import { useState, useEffect } from 'react';
import './RecentWork.scss'
import vpply from '../../assest/images/vpply.png'
function RecentWork() {

  return (
    <div id='RecentWork' className="recentWorkWrapper">
        <div className="recentWorkContainer">
            <div className="recentLeft">
                <div className="recentTitle">
                    <h1>RECENT WORK</h1>
                </div>
                <div className="recentDesc">
                    <h1>  <a className='vpplyLink' href="https://www.vpply.com/"  target="_blank" rel="noreferrer">VPPLY</a></h1>
                    <h1>VIDEO RESUME <br /> HR PLATFORM</h1>
                    <p>In his recent work, Alan focused on providing an optimal user 
                      experience by standardizing outputs using Vue.js/Nuxt.js and 
                     SASS for a responsive, mobile-first design. He improved SEO 
                     and accessibility, collaborated with Senior Full-Stack Developers
                      to provide technical solutions, and effectively integrated UI components 
                      with APIs and databases. His thorough testing ensured a fully functional, 
                      error-free website for users.</p>
                         <h3>2022</h3>
                </div>
            </div>
            <div className="recentRight" >
              <div className="imgContainer">
                <img src={vpply} alt="" />
                </div>
            </div>
         </div>

    </div>
  );
}

export default RecentWork;
