import React, { useRef, useEffect, useState } from 'react';
import CheilVideo from '../../assest/videos/cheilvideo.mp4'
import './Currentwork.scss'



function Currentwork () {

  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const container = containerRef.current;

    const handleScroll = () => {
      const rect = container.getBoundingClientRect();
      if (rect.bottom <= window.innerHeight) {
        video.play();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="currentWorkWrapper" >
        <div className="currentWorkContainer">
        <div className="liveTitle">
              <h3>LIVE</h3>
          </div>
          <div className="currentWorkDesc">
          <h2>CURRENTLY WORKING AT <a className='cheilLink' href="https://cheil.com/au"  target="_blank" rel="noreferrer">CHEIL AUSTRALIA</a>  TO develop <br />
             and optimise user-friendly websites that &nbsp; <span> help <br />
             Samsung drive maximum business impact</span> </h2>
          </div>
          <div className="skillContainer">
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>Sass</li>
              <li>Javascript</li>
              <li>React.Js</li>
              <li>Jquery</li>
              <li>Typescript</li>
              <li>Vanilla.Js</li>
              <li>Adobe Experince Manager</li>
              <li>Adobe Target</li>
              <li>Git</li>
              <li>Jira</li>
              <li>Bitbucket</li>
              <li>BrowserStack</li>
              <li>InsigthTech</li>
            </ul>
          </div>
          <div className="videoContainer"ref={containerRef}>
     <video ref={videoRef} controls={false} autoPlay muted playsInline loop>
        <source src={CheilVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
          </div>
        </div>
    </div>
  );
}

export default Currentwork