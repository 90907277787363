import { Element } from 'react-scroll';

import Currentwork from "./components/Currentwork/Currentwork";
import Projects from "./components/Projects/Projects";
import RecentWork from "./components/RecentWork/RecentWork";
import About from './components/About/About.jsx'
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";


function App() {
  
  return (
    <div className="App">
      <Navbar/>
      <Intro/>
      <Currentwork/>
      <RecentWork/>
      <Element name="work"><Projects/></Element>
      <Element name="about"><About/></Element>
      <Element name="contact"><Footer/></Element>    
      </div>
  );
}

export default App;

