import React, { useState, useEffect } from 'react';
import './Projects.scss';
import photographer from '../../assest/images/photographer.png'
import audiophile from '../../assest/images/audiophile.png'
import fylo from '../../assest/images/fylo1.png'


function Projects() {

  return (
  <div className="projectWrapper">
    <div className="projectContainer">
      <div className="projectTitle">
        <h1>FREELANCE <br />
            + PERSONAL PROJECTS</h1>
      </div>
      <div className="projectPhotographer">
        <div className="photographerPortfolioImgContainer">
          <a href="https://www.sinanbeytas.com/"  target="_blank" rel="noreferrer">
          <img src={photographer} alt="" />
          </a>
        </div>
        <div className="projectName">
          <h3>Photographer’s Portfolio WEBSITE</h3>
          <h3>2022</h3>
        </div>
        <div className="projectLinks">
        <a className='vpplyLink' href="https://www.sinanbeytas.com/"  target="_blank" rel="noreferrer">Live</a>
        </div>
      </div>
      <div className="projectContainer2">
      <div className="projectPhotographer">
        <div className="photographerPortfolioImgContainer2">
          <a href="https://audiophile-e-commerce-website.pages.dev/"  target="_blank" rel="noreferrer">
          <img src={audiophile} alt="" />
          </a>
        </div>
        <div className="projectName">
          <h3>AUDIOPHILE Ecommerce Website</h3>
          <h3>2021</h3>
        </div>
        <div className="projectLinks">
        <a  href="https://audiophile-e-commerce-website.pages.dev/"  target="_blank" rel="noreferrer">Live</a>
        <a  href="https://github.com/AlanOlgun/Audiophile-E-commerce-Website"  target="_blank" rel="noreferrer">Github</a>

        </div>
      </div>
      <div className="projectPhotographer3">
        <div className="photographerPortfolioImgContainer3">
          <a href="https://fylo-landing-pages.pages.dev/"  target="_blank" rel="noreferrer">
          <img src={fylo} alt="" />
          </a>
        </div>
        <div className="projectName">
          <h3>FYLO - LANDING PAGE</h3>
          <h3>2021</h3>
        </div>
        <div className="projectLinks">
        <a  href="https://fylo-landing-pages.pages.dev/"  target="_blank" rel="noreferrer">Live</a>
        <a  href="https://github.com/AlanOlgun/fylo-landing-page"  target="_blank" rel="noreferrer">Github</a>        </div>
      </div>
      </div>
    </div>
  </div>
  );
}

export default Projects;
