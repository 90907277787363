import { useState, useEffect } from 'react';
import './Footer.scss'
function Footer() {

  return (
    <div className="footerWrapper">
        <div className="footerContainer">
           <div className="footerDescEmail">
            <h1>
                LET'S CREATE <br />
                <span>SOMETHING</span> TOGETHER
            </h1>
            <a href="mailto:hello@alanolgun.com">HELLO@ALANOLGUN.COM </a> 
           </div>
            <div className="footerSocial">
                <div className="footerSocialContainer">
                <a href="https://github.com/AlanOlgun"  target="_blank" rel="noreferrer">GITHUB</a>
                <a href="https://www.instagram.com/_alanolgun_/"  target="_blank" rel="noreferrer">INSTAGRAM</a>
                <a href="https://www.linkedin.com/in/alan-olgun-292b93221/"  target="_blank" rel="noreferrer">LINKEDIN</a>
                <a href="https://linktr.ee/alanolgun?fbclid=PAAaa4y2FxeobW3v1_hFDwK1fdGPuaMCcS1ByXj6AzFbtM6P5u9rj1BMRidls"  target="_blank" rel="noreferrer">LINKTREE</a>

                </div>
                <div className="footerBuildContainer">
                    <h3>Built by Alan Olgun © 2023</h3>
                </div>
            </div>
        
         </div>

    </div>
  );
}

export default Footer;
