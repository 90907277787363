import React, { useState } from 'react';
import './About.scss';
import portrait from '../../assest/images/1.jpeg';
import portrait2 from '../../assest/images/2.jpeg';

function About() {
  return (
    <div className="AboutWrapper">
        <div className="AboutContainer">
            <div className="AboutSecContainer">
            <div className="AboutLeft">
                <div className="AboutTitle">
                    <h1>ABOUT</h1>
                </div>
                <div className="image-container">
          <img className="portrait" src={portrait} alt="" />
        </div>
         </div>
            <div className="AboutRight" >
                <div className="AboutHeadline">
                    <h1>Hey, <br /> I'M ALAN!</h1>
                </div>
                <div className="AboutText">
                    <p>Alan is a passionate Front-End Software Engineer with  two years of experience. 
                      He excels at problem-solving and thrives on optimizing his development processes
                      for quick project launches. Alan enjoys collaborating with product experts and brings
                      a detail-oriented approach to visual problem-solving, enabling him to tackle complex 
                      challenges with innovative coding solutions.
                      <br />
                      <br />
                      Always eager to learn, Alan stays up-to-date with the latest front-end libraries and
                       frameworks, constantly refining his skills to advance in his career. His commitment to
                        continuous improvement and collaboration has consistently contributed to the timely completion 
                        of projects and the delivery of exceptional results. </p>
                </div>
                <div className="ResumeLink">
                <a href="https://www.figma.com/proto/N91qHzu2UQlhf7gEWwicoV/Alan's-CV%2FResume?page-id=84%3A70&node-id=1285-965&viewport=1157%2C-606%2C0.42&scaling=min-zoom"  target="_blank" rel="noreferrer">VIEW RESUME</a>
                  </div>
            </div>
            </div>
            <div className="AboutSkillContainer">
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>Sass</li>
              <li>Bootstrap5</li>
              <li>Javascript</li>
              <li>React.Js</li>
              <li>Next.JS</li>
              <li>Three.JS</li>
              <li>Node.JS</li>
              <li>Vue.JS</li>
              <li>Nuxt.JS</li>
              <li>Jquery</li>
              <li>Typescript</li>
              <li>Vanilla.Js</li>
              <li>Adobe Experince Manager</li>
              <li>Adobe Target</li>
              <li>Agile</li>
              <li>QA Testing</li>
              <li>A/B Testing</li>
              <li>Git</li>
              <li>Jira</li>
              <li>Bitbucket</li>
              <li>BrowserStack</li>
              <li>InsigthTech</li>
            </ul>
            </div>

         </div>
    </div>
  );
}

export default About;
