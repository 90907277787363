import { useState, useEffect } from 'react';
import './Navbar.scss'
import { Link, Element } from 'react-scroll';

function Navbar() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navbarClass = scrollPosition > 0 ? 'navbarContainer scrolled' : 'navbarContainer';
  const HamburgerSlice = scrollPosition > 30 ? 'HamburgerSlice scrolled' : 'HamburgerSlice';

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="navbarWrapper">
      <div className={navbarClass}>
        <a className='nameLink' href="/">ALAN OLGUN</a>
        <div className="hamburgerMenu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
  <div className={`${HamburgerSlice} ${isMenuOpen ? 'rotate45' : ''}`}></div>
  {!isMenuOpen && <div className={HamburgerSlice}></div>}
  <div className={`${HamburgerSlice} ${isMenuOpen ? 'rotate-45' : ''}`}></div>
</div>




        <div className={`navbarSections${isMenuOpen ? ' open' : ' close'}`}>
          <Link className='Links About' to="about" smooth={true} duration={500} offset={-50} onClick={handleLinkClick}>ABOUT</Link>
          <Link className='Links Work' Links to="work" smooth={true} duration={500} offset={-50} onClick={handleLinkClick}>WORK</Link>
          <Link className='Links Contact' to="contact" smooth={true} duration={500} offset={-50} onClick={handleLinkClick}>CONTACT</Link>
          <h2 className='navbarBuiltText' >Built by Alan Olgun © 2023</h2>
        </div>
      </div>
    </div>
  );
}


export default Navbar;