import { useState, useEffect } from 'react';
import './Intro.scss'
import cheil from '../../assest/images/cheilintro.png'


function Intro() {

    // const [loaded, setLoaded] = useState(false);

    // useEffect(() => {
    //   setLoaded(true);
    // }, []);
    const [scrollPosition, setScrollPosition] = useState(0);

 
    useEffect(() => {
        const handleScroll = () => {
          const position = window.pageYOffset;
          setScrollPosition(position);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const IntroContainerClass = scrollPosition > 0 ? 'IntroContainer scrolled' : 'IntroContainer';

  return (
    <div className="IntroWrapper">
        <div className={IntroContainerClass}>
     <div className="introChildContainer">
        <div className='parentSpanFirst'>
            <span className='childSpan First'>F<br /></span>
            <span className='childSpan Second'>R</span>
            <span className='childSpan Third'>O</span>
            <span className='childSpan Forth'>N</span>
            <span className='childSpan Fifth'>T</span>
            <span className='childSpan Sixth'>E</span>
            <span className='childSpan Seventh'>N</span>
            <span className='childSpan Eight'>D</span>
        </div>
     </div>
     <div className="introChildContainer">
        <div className="lineContainer">
            <div className="line"></div>
        </div>
        <div className='parentSpanSecond'>
            <span className='childSpan First'>D</span>
            <span className='childSpan Second'>E</span>
            <span className='childSpan Third'>V</span>
            <span className='childSpan Forth'>E</span>
            <span className='childSpan Fifth'>L</span>
            <span className='childSpan Sixth'>O</span>
            <span className='childSpan Seventh'>P</span>
            <span className='childSpan Eight'>E</span>
            <span className='childSpan Ninth'>R</span>
        </div>
     </div>
     <div className="introChildContainer">
        <div className='parentSpanThird'>
            <span className=' childSpan First'>A</span>
            <span className=' childSpan Second'>T</span>
        </div>
        <div className="introImgContainer">
            <img src={cheil} alt="" />
        </div>
     </div>
     <div className="introChildContainer">
        <div className='parentSpanForth'>
            <span className=' childSpan First'>C</span>
            <span className=' childSpan Second'>H</span>
            <span className=' childSpan Third'>E</span>
            <span className=' childSpan Forth'>I</span>
            <span className=' childSpan Fifth'>L</span>
        </div>
        <div className='parentSpanForth'>
            <span className=' childSpan First'>A</span>
            <span className=' childSpan Second'>U</span>
            <span className=' childSpan Third'>S</span>
            <span className=' childSpan Forth'>T</span>
            <span className=' childSpan Fifth'>R</span>
            <span className=' childSpan Sixth'>A</span>
            <span className=' childSpan Seventh'>L</span>
            <span className=' childSpan Eight'>I</span>
            <span className=' childSpan Ninth'>A</span>
        </div>
     </div>
        </div>
    </div>
  );
}

export default Intro;
